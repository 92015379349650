import AccordionWithChips, { AccordionWithChipsProps } from "@components/Accordion/AccordionWIthChips";
import { FOOD_PREFERENCES_LIST } from "@configs/onboarding.personalDetails.config";
import { Item } from "@libs/types";
import { convertToStartCase } from "@utils/formatter";
import { memo, useState } from "react";

interface Props {
  accordionKey: string;
  isAccordionOpen: boolean;
  toggleAccordionByKey: (key: string) => void;
  savedValue: string;
  updateGlobalStore: (key, value) => void;
}

const FoodPreference = ({
  accordionKey,
  isAccordionOpen,
  toggleAccordionByKey,
  savedValue,
  updateGlobalStore,
}: Props) => {
  const [value, setValue] = useState(savedValue);

  const onItemSelect = (item: Item) => {
    setValue(item.value);
    updateGlobalStore(accordionKey, item.value);
  };

  const props: AccordionWithChipsProps = {
    list: [...FOOD_PREFERENCES_LIST].slice(0, 4), // remove the "any" value
    title: "Food preference",
    placeholder: "Eg: Vegan",
    selectedItem: value,
    value: convertToStartCase(value),
    isOpen: isAccordionOpen,
    accordionKey,
    onItemSelect,
    toggleAccordion: toggleAccordionByKey,
  };

  return <AccordionWithChips {...props} />;
};

export default memo(FoodPreference);
