import { Item } from "@libs/types";

export const SETTLE_DOWN: Array<Item> = [
  {
    id: 0,
    displayText: "6 months",
    value: "6 months",
  },
  {
    id: 1,
    displayText: "In a year",
    value: "in a year",
  },
  {
    id: 2,
    displayText: "1 - 2 years",
    value: "1-2 years",
  },
  {
    id: 3,
    displayText: "2+ years",
    value: "2+ years",
  },
];

export interface HomeTown {
  id: number;
  type: string;
  displayText: string;
  value: string;
  geometry: {
    type: string;
    coordinates: Array<number>;
  };
  properties: {
    city: string;
    state: string;
    country: string;
    tag: null | string;
  };
}

export const HOME_TOWNS: Array<HomeTown> = [
  {
    id: 0,
    type: "Feature",
    geometry: { type: "Point", coordinates: [77.1025, 28.7041] },
    properties: {
      city: "Delhi",
      state: "Delhi",
      country: "India",
      tag: null,
    },
    value: "Delhi, Delhi",
    displayText: "Delhi",
  },
  {
    id: 1,
    type: "Feature",
    geometry: { type: "Point", coordinates: [72.8777, 19.076] },
    properties: {
      city: "Mumbai",
      state: "Maharashtra",
      country: "India",
      tag: null,
    },
    value: "Mumbai, Maharashtra",
    displayText: "Mumbai",
  },
  {
    id: 2,
    type: "Feature",
    geometry: { type: "Point", coordinates: [77.5946, 12.9716] },
    properties: {
      city: "Bangalore",
      state: "Karnataka",
      country: "India",
      tag: null,
    },
    value: "Bangalore, Karnataka",
    displayText: "Bangalore",
  },
  {
    id: 3,
    type: "Feature",
    geometry: { type: "Point", coordinates: [80.2707, 13.0827] },
    properties: {
      city: "Chennai",
      state: "Tamil Nadu",
      country: "India",
      tag: null,
    },
    value: "Chennai, Tamil Nadu",
    displayText: "Chennai",
  },
  {
    id: 4,
    type: "Feature",
    geometry: { type: "Point", coordinates: [78.4867, 17.385] },
    properties: {
      city: "Hyderabad",
      state: "Telangana",
      country: "India",
      tag: null,
    },
    value: "Hyderabad, Telangana",
    displayText: "Hyderabad",
  },
  {
    id: 5,
    type: "Feature",
    geometry: { type: "Point", coordinates: [88.3639, 22.5726] },
    properties: {
      city: "Kolkata",
      state: "West Bengal",
      country: "India",
      tag: null,
    },
    value: "Kolkata, West Bengal",
    displayText: "Kolkata",
  },
];

export const HOME_TOWNS_ASTRO = [
  {
    type: "Feature",
    description: "Delhi, Delhi",
    terms: [
      {
        value: "Delhi",
      },
      {
        value: "Delhi",
      },
      {
        value: "India",
      },
    ],

    geometry: {
      type: "Point",
      location: {
        lng: 77.1025,
        lat: 28.7041,
      },
    },
  },

  {
    type: "Feature",
    description: "Kolkata, West Bengal",
    terms: [
      {
        value: "Kolkata",
      },
      {
        value: "West Bengal",
      },
      {
        value: "India",
      },
    ],
    geometry: {
      type: "Point",
      location: {
        lng: 88.3639,
        lat: 22.5726,
      },
    },
  },

  {
    type: "Feature",
    description: "Bangalore, Karnataka",
    terms: [
      {
        value: "Bangalore",
      },
      {
        value: "Karnataka",
      },
      {
        value: "India",
      },
    ],
    geometry: {
      type: "Point",
      location: {
        lng: 77.5946,
        lat: 12.9716,
      },
    },
  },

  {
    type: "Feature",
    description: "Chennai, Tamil Nadu",
    terms: [
      {
        value: "Chennai",
      },
      {
        value: "Tamil Nadu",
      },
      {
        value: "India",
      },
    ],
    geometry: {
      type: "Point",
      location: {
        lng: 80.2707,
        lat: 13.0827,
      },
    },
  },

  {
    type: "Feature",
    description: "Hyderabad, Telangana",
    terms: [
      {
        value: "Hyderabad",
      },
      {
        value: "Telangana",
      },
      {
        value: "India",
      },
    ],
    geometry: {
      type: "Point",
      location: {
        lng: 78.4867,
        lat: 17.385,
      },
    },
  },
  {
    type: "Feature",
    description: "Mumbai, Maharashtra",
    terms: [
      {
        value: "Mumbai",
      },
      {
        value: "Maharashtra",
      },
      {
        value: "India",
      },
    ],
    geometry: {
      type: "Point",
      location: {
        lng: 72.8777,
        lat: 19.076,
      },
    },
  },
];

export const HEIGHT_LIST: Array<Item> = [
  {
    id: 0,
    displayText: "",
    value: "53",
  },
  {
    id: 1,
    displayText: "",
    value: "54",
  },
  {
    id: 2,
    displayText: "",
    value: "55",
  },
  {
    id: 3,
    displayText: "",
    value: "56",
  },
  {
    id: 4,
    displayText: "",
    value: "57",
  },
  {
    id: 5,
    displayText: "",
    value: "58",
  },
  {
    id: 6,
    displayText: "",
    value: "59",
  },
  {
    id: 7,
    displayText: "",
    value: "60",
  },
  {
    id: 8,
    displayText: "",
    value: "61",
  },
  {
    id: 9,
    displayText: "",
    value: "62",
  },
  {
    id: 10,
    displayText: "",
    value: "63",
  },
  {
    id: 11,
    displayText: "",
    value: "64",
  },
  {
    id: 12,
    displayText: "",
    value: "65",
  },

  {
    id: 13,
    displayText: "",
    value: "66",
  },
  {
    id: 14,
    displayText: "",
    value: "67",
  },

  {
    id: 15,
    displayText: "",
    value: "68",
  },
  {
    id: 16,
    displayText: "",
    value: "69",
  },
  {
    id: 17,
    displayText: "",
    value: "70",
  },
  {
    id: 18,
    displayText: "",
    value: "71",
  },
  {
    id: 19,
    displayText: "",
    value: "72",
  },
  {
    id: 20,
    displayText: "",
    value: "73",
  },
  {
    id: 21,
    displayText: "",
    value: "74",
  },
  {
    id: 22,
    displayText: "",
    value: "75",
  },
  {
    id: 23,
    displayText: "",
    value: "76",
  },
  {
    id: 24,
    displayText: "",
    value: "77",
  },
];

export const QUALIFICATIONS_LIST: Array<Item> = [
  {
    id: 0,
    displayText: "Masters of Business Administration",
    value: "Masters of Business Administration",
  },
  {
    id: 1,
    displayText: "Bachelor of Commerce",
    value: "Bachelor of Commerce",
  },
  {
    id: 2,
    displayText: "Bachelor of Technology",
    value: "Bachelor of Technology",
  },
  {
    id: 3,
    displayText: "Bachelor of Arts",
    value: "Bachelor of Arts",
  },
  {
    id: 4,
    displayText: "Bachelor of Engineering",
    value: "Bachelor of Engineering",
  },
  {
    id: 5,
    displayText: "Master of Arts",
    value: "Master of Arts",
  },
];

export const JOBS_LIST: Array<Item> = [
  {
    id: 0,
    displayText: "Software Engineer",
    value: "Software Engineer",
  },
  {
    id: 1,
    displayText: "Business Analyst",
    value: "Business Analyst",
  },
  {
    id: 2,
    displayText: "Team Lead",
    value: "Team Lead",
  },
  {
    id: 3,
    displayText: "Assistant Manager",
    value: "Assistant Manager",
  },
  {
    id: 4,
    displayText: "Consultant",
    value: "Consultant",
  },
  {
    id: 5,
    displayText: "Entrepreneur",
    value: "Entrepreneur",
  },
];

export const COLLEGES_LIST: Array<Item> = [
  {
    id: 0,
    displayText: "University Of Delhi",
    value: "University Of Delhi",
  },
  {
    id: 1,
    displayText: "University Of Mumbai",
    value: "University Of Mumbai",
  },
  {
    id: 2,
    displayText: "University Of Pune",
    value: "University Of Pune",
  },
  {
    id: 3,
    displayText: "Gujarat University",
    value: "Gujarat University",
  },
  {
    id: 4,
    displayText: "Anna University Chennai",
    value: "Anna University Chennai",
  },
  {
    id: 5,
    displayText: "Jntu College Of Engineering",
    value: "Jntu College Of Engineering",
  },
];

export const SALARY_RANGE_LIST: Array<Item> = [
  {
    id: 0,
    value: "0 - 2 Lpa",
    displayText: "0 - 2 Lpa",
  },
  {
    id: 1,
    value: "2 - 4 Lpa",
    displayText: "2 - 4 Lpa",
  },
  {
    id: 2,
    value: "4 - 7 Lpa",
    displayText: "4 - 7 Lpa",
  },
  {
    id: 3,
    value: "7 - 10 Lpa",
    displayText: "7 - 10 Lpa",
  },
  {
    id: 4,
    value: "10 - 15 Lpa",
    displayText: "10 - 15 Lpa",
  },
  {
    id: 5,
    value: "15 - 20 Lpa",
    displayText: "15 - 20 Lpa",
  },
  {
    id: 6,
    value: "20 - 30 Lpa",
    displayText: "20 - 30 Lpa",
  },
  {
    id: 7,
    value: "30 - 50 Lpa",
    displayText: "30 - 50 Lpa",
  },
  {
    id: 8,
    value: "50 - 100 Lpa",
    displayText: "50+ Lpa",
  },
];

export const FOOD_PREFERENCES_LIST: Array<Item> = [
  {
    id: 0,
    displayText: "Vegetarian",
    value: "vegetarian",
  },
  {
    id: 1,
    displayText: "Non vegetarian",
    value: "non_vegetarian",
  },
  {
    id: 2,
    displayText: "Vegan",
    value: "vegan",
  },
  {
    id: 3,
    displayText: "Eggetarian",
    value: "eggetarian",
  },
  {
    id: 4,
    displayText: "Any",
    value: "any",
  },
];

export const COMPANIES_LIST: Array<Item> = [
  {
    id: 0,
    displayText: "Accenture",
    value: "Accenture",
  },
  {
    id: 1,
    displayText: "Adobe",
    value: "Adobe",
  },
  {
    id: 2,
    displayText: "TCS",
    value: "TCS",
  },
  {
    id: 3,
    displayText: "Amazon",
    value: "Amazon",
  },
  {
    id: 4,
    displayText: "Infosys Ltd",
    value: "Infosys Ltd",
  },
  {
    id: 5,
    displayText: "IBM",
    value: "IBM",
  },
];

export const PERSONAL_DETAILS_MATCHES_COUNTS = {
  Hindu: "5000+",
  Muslim: "3000+",
  Christian: "2000+",
  Sikh: "2000+",
  Buddhist: "1000+",
  Jain: "1000+",
  Other: "1000+",
};
