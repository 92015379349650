import AccordionWithList, { AccordionWithListProps } from "@components/Accordion/AccordionWithList";
import CollegeIcon from "@components/icons/CollegeIcon";
import SearchList, { SearchListProps } from "@components/SearchList";
import { COLLEGES_LIST } from "@configs/onboarding.personalDetails.config";
import { Item } from "@libs/types";
import { convertToStartCase } from "@utils/formatter";
import { searchCollegeNames } from "api/search";
import { memo, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";

interface Props {
  accordionKey: string;
  isAccordionOpen: boolean;
  toggleAccordionByKey: (key: string) => void;
  savedValue: string;
  updateGlobalStore: (key, value) => void;
}

const College = ({ accordionKey, isAccordionOpen, toggleAccordionByKey, savedValue, updateGlobalStore }: Props) => {
  const [isBottomSheetOpen, toggleBottomSheet] = useState(false);
  const [value, setValue] = useState(savedValue);

  const updateValue = (item: Item) => {
    setValue(item.value);
    toggleBottomSheet(false);
    updateGlobalStore(accordionKey, item.value);
  };

  const props: AccordionWithListProps = {
    list: COLLEGES_LIST,
    title: "College",
    placeholder: "Eg: IIM Bangalore",
    showSeeMore: true,
    onSeeMorePress: () => toggleBottomSheet(true),
    selectedItem: value,
    value: convertToStartCase(value),
    isOpen: isAccordionOpen,
    accordionKey,
    toggleAccordion: toggleAccordionByKey,
    onItemSelect: updateValue,
  };

  const KEY_TO_SEARCH_LIST_PROPS: SearchListProps = {
    onSelect: updateValue,
    onSearchCallback: searchCollegeNames,
    placeholder: "Search College",
    initialData: COLLEGES_LIST,
    selectedData: value,
    Icon: CollegeIcon,
  };

  return (
    <>
      <AccordionWithList {...props} />
      <BottomSheet open={isBottomSheetOpen} onDismiss={() => toggleBottomSheet(false)}>
        <SearchList {...KEY_TO_SEARCH_LIST_PROPS} />
      </BottomSheet>
    </>
  );
};

export default memo(College);
