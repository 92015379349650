class AFApi {
  private AFinstance: any;
  constructor() {
    if (typeof window !== 'undefined') {
      this.AFinstance = new (window as any).AF();
    }
  }
  public getAppsFlyerInstance() {
    if (this.AFinstance) {
      return this.AFinstance;
    }
  }
}
export default AFApi;


