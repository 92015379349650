import AccordionWithChips, { AccordionWithChipsProps } from "@components/Accordion/AccordionWIthChips";
import { SMOKE_PREFERENCE_LIST } from "@configs/onBoarding.config";
import { Item } from "@libs/types";
import { convertToStartCase } from "@utils/formatter";
import { memo, useState } from "react";

interface ISmokeProps {
  accordionKey: string;
  isAccordionOpen: boolean;
  toggleAccordionByKey: (key: string) => void;
  savedValue: string;
  updateGlobalStore: (key, value) => void;
}

// Smoke Accordion
const Smoke = ({ accordionKey, isAccordionOpen, toggleAccordionByKey, savedValue, updateGlobalStore }: ISmokeProps) => {
  const [value, setValue] = useState(savedValue); // set state on value change

  // on Item change in the accordion ItemSelect will be called
  const onItemSelect = (item: Item) => {
    setValue(item.value); // updates the state value
    updateGlobalStore(accordionKey, item.value); // update parents state with the new value.
  };

  // Smoke accordion props
  const props: AccordionWithChipsProps = {
    list: SMOKE_PREFERENCE_LIST,
    title: "Smoke",
    placeholder: "Eg: Never",
    selectedItem: value,
    value: convertToStartCase(value),
    isOpen: isAccordionOpen,
    accordionKey,
    onItemSelect,
    toggleAccordion: toggleAccordionByKey,
  };

  return <AccordionWithChips {...props} />;
};

export default memo(Smoke);
