import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

{
  /*
  TO-DO: Move below key to environment file
  Useful link for Sourcemap:
  https://nextjs.org/docs/advanced-features/source-maps
  https://docs.bugsnag.com/build-integrations/js/source-maps-browser/#examples
*/
}

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNUG_API_KEY,
  plugins: [new BugsnagPluginReact(React)],
  // releaseStage: process.env.NEXT_PUBLIC_BUGSNAG_RELEASE_STAGE,
  // sourceMapRoot: "../../",
  // sourceMapPath: "../../",
  appVersion: process.env.NEXT_PUBLIC_BUGSNAG_APP_VERSION,
});

const plugin = Bugsnag.getPlugin("react");
export const ErrorBoundary = plugin.createErrorBoundary();

export default Bugsnag;
