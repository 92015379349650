import { Dispatch, FC, SetStateAction } from "react";
import OtpInput from "react-otp-input";

interface Props {
  otp: string;
  setOtp: Dispatch<SetStateAction<string>>;
  callbackOtpVerify: (value) => void;
}

const OtpInputWrapper: FC<Props> = ({ otp, setOtp, callbackOtpVerify }) => {
  const handleChange = (value) => {
    setOtp(value);
    if (value.length === 4) {
      callbackOtpVerify(value);
    }
  };

  return (
    <OtpInput
      value={otp}
      onChange={handleChange}
      numInputs={4}
      inputStyle="!w-8 text-xl mx-1 mt-3 border-b-2 border-grayIcon text-grayIcon focus:outline-none"
      shouldAutoFocus
      isInputNum
    />
  );
};

export default OtpInputWrapper;
