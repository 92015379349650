// don't start endpoints name with / : eg : not /api/v1/users instead api/v1/users
export const ENDPOINTS = {
  SEND_OTP: 'auth/otp/send/',
  VERIFY_OTP: 'auth/otp/verify/',
  ME: 'me/',
  UPLOAD_IMAGE: 'me/images/',
  DELETE_IMAGE: 'me/images/',
  GET_RECOMMENDATIONS: 'me/recommendations/',
  GET_RECOMMENDATION_COUNT: 'me/webapp/recommendations/count/',
  GENERATE_RECOM_FOR_WEB_WPP: 'me/webapp/recommendations/',
  PARTNER_PREFERENCE: 'me/partner-preferences/create-preference/',
  // TODO : need to refactor the endpoint name
  PARTNER_PREFERENCE_NEW: 'me/partner-preferences/',
  LOCATION_HOMETOWN: 'me/locations/hometown/',
  DEACTIVATE_ACCOUNT: 'me/snooze/',
  REFRESH_RECOMMENDATIONS: 'me/partner-preferences/refresh-recommendations/',
  REARRANGE_IMAGES: 'me/images/rearrange/',

  SUBMIT_FEEDBACK: 'me/feedback/',
  GET_RECEIVED_REQUESTS: 'me/connections/received',
  GET_RECEIVED_REQUESTS_COUNT: 'me/connections/received/count/',
  GET_SENT_REQUESTS: 'me/sent',
  GET_QUEUED_REQUESTS: 'me/queued/',
  GET_USER_ACTIVE_PLAN: 'me/user-active-plans/',

  // configs

  SPARKLES_CONFIG: 'sparkle-config/',
  GENERAL_CONFIG: 'config/',
  PRODUCTION_CONFIG: 'config/',
  CATEGORIES_CONFIG: 'me/app-config/categories/',

  GET_EXPIRY_STATUS: 'me/training-data',
};

type ACTION =
  | {
      type: 'SEND_OTP';
      payload: {
        mobile: string;
        medium: 'SMS' | 'IVR';
        isdCode?: string;
      };
    }
  | {
      type: 'VERIFY_OTP';
      payload: {
        mobile: string;
        otp: string;
        isdCode?: string;
        afUserIdWeb?: string;
      };
    }
  | {
      type: 'ME';
      payload: {
        [key: string]: string;
      };
    };

export const getRequestBody = (action: ACTION) => {
  switch (action.type) {
    case 'SEND_OTP': {
      const { mobile, medium, isdCode } = action.payload;
      if (!mobile) throw new Error('mobile number is required');
      return {
        mobile,
        medium: medium || 'SMS',
        isd_code: isdCode || '91',
      };
    }
    case 'VERIFY_OTP': {
      const { mobile, otp, isdCode, afUserIdWeb } = action.payload;

      if (!mobile || !otp) throw new Error('mobile number and otp , both are required');
      return {
        mobile,
        otp,
        isd_code: isdCode || '91',
        afUserIdWeb,
      };
    }
    case 'ME': {
      return action.payload;
    }
  }
};
