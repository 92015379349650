/* eslint-disable no-shadow */
export const SCREEN_NAMES = {
  SIGN_UP: 'signup',
  SUBMIT_MOBILE_NUMBER: 'submit_mobile_no',
  ENTER_OTP: 'enter_otp',
  PROFILE_SIGN_UP_NAME: 'profile_signup_name',
  PROFILE_SIGN_UP_GENDER: 'profile_signup_gender',
  PROFILE_SIGN_UP_BIRTHDAY: 'profile_signup_birthday',
  PROFILE_SIGN_UP_EMAIL_ID: 'profile_signup_emailid',
  SIGN_UP_SNEAK_PEAK: 'signup_sneak_peak',
  SIGNUP_ADD_PHOTOS: 'signup_add_photos',
  AI_ANIMATION: 'ai_animation',
  AI_BOT_PERSONAL_DETAILS: 'ai_bot_personal_details',
  DOWNLOAD_APP: 'download_app',
  RECOMMENDED_PROFILE: 'recommended_profile',
  RECOMMENDATION_SCREEN: 'recommendation_screen',
  PARTNER_PREFERENCES: 'partner_preference',
  SETTINGS: 'settings',
  PERSONAL_DETAILS: 'personal_details',
  SENT_REQUEST: 'sent_requests',
  SENT_REQUEST_LIST_VIEW: 'list_view',
  QUEUED_REQUEST_SCREEN: 'queued_requests_screen',
  SENT_REQUEST_DETAILED_VIEW: 'detailed_view',
  RECEIVED_REQUEST: 'recieved_request',
  MY_PROFILE: 'my_profile',
  PREMIUM_PLAN: 'premium_plans',
  PAYMENT_SUCCESS: 'payment_success',
  PAYEMENT_FAILURE: 'payment_failure',

  //Govt-id Screen
  GV_HOW_IT_WORKS: 'gv_how_it_works_screen',
  GV_ADD_ID: 'gv_add_id_screen',
  GV_PHOTO_FAILED: 'gv_photo_failed_screen',
  GV_INTRO_POP_UP: 'gv_intro_pop_up',
  GV_UNLIMITED_CONNECT_POP_UP: 'unlimited_connects_pop_up',
  GV_VERIFY_ME_POP_UP: 'gv_verify_me_pop_up_web',
  PROFILE_SCREEN: 'profile_screen',
  SELFIE_VERIFY: 'selfie_verify',
};

export const EVENT_TYPES = {
  SCREEN_OPENING: 'opnscr',
  USER_ACTION: 'userac',
  BACKEND_EVENT: 'bckend',
};

// eslint-disable-next-line no-shadow
enum ONBOARDING_EVENT_NAMES {
  // onboarding events -------------------------------------------------
  landingpage_screen_web = 'landingpage_screen_web',
  signup_started_web = 'signup_started_web',
  mobile_no_web = 'submit_mobile_no_web',
  mobile_number_submit_web = 'mobile_number_submit_web',
  otp_sent_web = '_otp_sent_web',
  enter_otp_screen_web = 'enter_otp_screen_web',
  otp_received_web = '_otp_received_web',
  otp_submit_web = 'otp_submit_web',
  resend_sms_web = 'resend_sms_web',
  _sms_send_initiated_web = '_sms_send_initiated_web',
  _sms_received_web = '_sms_received_web',
  get_otp_on_call_web = 'get_otp_on_call_web',
  incorrect_otp_entered_web = 'incorrect_otp_entered_web',
  click_contact_us_web = 'click_contact_us_web',
  profile_signup_name_web = 'profile_signup_name_web',
  continue_on_profile_name_web = 'continue_on_profile_name_web',
  profile_signup_gender_web = 'profile_signup_gender_web',
  gender_select_web = 'gender_select_web',
  profile_signup_birthday_web = 'profile_signup_birthday_web',
  continue_with_birthdate_web = 'continue_with_birthdate_web',
  profile_signup_emailid_web = 'profile_signup_emailid_web',
  continue_with_emailid_web = 'continue_with_emailid_web',
  invalid_emailid_web = 'invalid_emailid_web',
  signup_sneak_peek_web = 'signup_sneak_peek_web',
  continue_with_sneak_peek_web = 'continue_with_sneak_peek_web',
  signup_add_photos_web = 'signup_add_photos_web',
  signup_select_photos_web = 'signup_select_photos_web',
  photo_selection_limit_reached_web = 'photo_selection_limit_reached_web',
  photo_upload_web = 'photo_upload_web',
  continue_add_photos_web = 'continue_add_photos_web',
  _photos_upload_success_web = '_photos_upload_success_web',
  signup_complete_web = '_signup_complete_web',
  ai_animation_web = 'ai_animation_web',

  // --------
  recommended_profile_web = 'recommended_profile_web',
  click_connect_web = 'click_connect_web',
  click_not_interested_web = 'click_not_interested_web',
  click_profile_web = 'click_profile_web',

  // --------
  download_app_screen_web = 'download_app_screen_web',
  click_download_app_web = 'click_download_app_web',
  continue_app_screen_web = 'continue_app_screen_web',
  click_continue_app_web = 'click_continue_app_web',

  // Partner Preferences Keys
  partner_preference_web = 'partner_preference_web',
  click_change_mandatory_preferences_web = 'click_change_mandatory_preferences_web',
  click_save_mandatory_preferences_web = 'click_save_mandatory_preferences_web',
  partner_preference_updated_web = 'partner_preference_updated_web',
  update_must_have_filters_web = 'update_must_have_filters_web',
  update_nice_to_have_filters_web = 'update_nice_to_have_filters_web',
  lifestyle_pop_up_web = 'lifestyle_pop_up_web',
  male_salary_above_seven_lakh_web = 'male_salary_above_seven_lakh_web',
  complete_registration_male_web = 'complete_registration_male_web',
  click_save_partner_preference_web = 'click_save_partner_preference_web',

  // Edit Profile
  edit_personal_profile_web = 'edit_personal_profile_web',
  edit_personal_profile_v2_continue_web = 'edit_personal_profile_v2_continue_web',

  // Deactivate Account
  click_deactivate_account_web = 'click_deactivate_account_web',
  deactivate_account_select_reason_web = 'deactivate_account_select_reason_web',
  deactivate_account_web = 'deactivate_account_web',
  delete_account_deactivate_web = 'delete_account_deactivate_web',

  // AI Bot events

  ai_bot_animation_web = 'ai_bot_animation_web',
  ai_bot_prefilled_personal_details_web = 'ai_bot_prefilled_personal_details_web',
  ai_bot_prefilled_personal_details_back_web = 'ai_bot_prefilled_personal_details_back_web',
  ai_bot_prefilled_personal_continue_web = 'ai_bot_prefilled_personal_continue_web',
  field_updates_web = 'field_updates_web',
  personal_details_updated_web = 'personal_details_updated_web',

  female_salary_above_four_lakh_web = 'female_salary_above_four_lakh_web',
  user_registration_completed_web = 'user_registration_completed_web',
  twoX_matches_upgrade_web = '2x_matches_upgrade_web',
  continue_with_basic_details_web = 'continue_with_basic_details_web',
  female_registration_age_24_to_35_web = 'female_registration_age_24_to_35_web',
  complete_registration_female_web = 'complete_registration_female_web',

  // Sent Tab Events
  sent_requests_appeared_web = 'sent_requests_appeared_web',
  nudge_click_send_sparkle = 'nudge_click_send_sparkle',
  click_send_sparkel_message_web = 'click_send_sparkle_message',
  queued_requests_screen_web = 'queued_requests_screen_web',
  click_queued_requests_screen_web = 'click_queued_requests_screen_web',
  click_send_queued_interest_web = 'click_send_queued_interest_web',

  // Received Tab Events
  recieved_request_web = 'recieved_request_web',
  click_accept_web = 'click_accept_web',
  click_decline_web = 'click_decline_web',
  blurred_profile_click_web = 'blurred_profile_click_web',
  scratch_profile_web = 'scratch_profile_web',
  received_request_click_upgrade_web = 'received_request_click_upgrade_web',

  // Upgrade Tab

  profile_click_upgrade_web = 'profile_click_upgrade_web',
  premium_click_upgrade_web = 'premium_click_upgrade_web',
  _payment_success_web = '_payment_success_web',
  premium_continue_web = 'premium_continue_web',
  payment_failure_web = 'payment_failure_web',
  click_back_to_premium_web = 'click_back_to_premium_web',
  click_retry_payment_web = 'click_retry_payment_web',
  premium_plans_web = 'premium_plans_web',
  premium_plan_select_web = 'premium_plan_select_web',
  profile_click_myplan_web = 'profile_click_myplan_web',

  // Sparkle
  click_sparkle_message_web = 'click_sparkle_message_web',
  sparkle_request_accept_web = 'sparkle_request_accept_web',
  sparkle_request_decline_web = 'sparkle_request_decline_web',
  vas_plans_web = 'vas_plans',
  vas_click_upgrade_web = 'vas_click_upgrade',
  vas_continue_web = 'vas_continue_web',
  nudge_click_send_sparkle_web = 'nudge_click_send_sparkle_web',

  //Govt-id Verification
  gv_how_it_works_screen_web = 'gv_how_it_works_screen_web',
  gv_click_how_it_works_continue_web = 'gv_click_how_it_works_continue_web',
  gv_click_how_it_works_later_web = 'gv_click_how_it_works_later_web',
  gv_add_id_screen_web = 'gv_add_id_screen_web',
  gv_verification_submit_web = 'gv_verification_submit_web',
  gv_photo_failed_screen_web = 'gv_photo_failed_screen_web',
  gv_submit_click_do_it_later_web = 'gv_submit_click_do_it_later_web',
  gv_submit_click_retry_web = 'gv_submit_click_retry_web',
  // gv nudge new pop up
  gv_intro_pop_up_web = 'gv_intro_pop_up_web',
  unlimited_connects_pop_up_web = 'unlimited_connects_pop_up_web',
  click_gv_get_verified_web = 'click_gv_get_verified_web',
  click_skip_for_now_web = 'click_skip_for_now_web',
  click_upgrade_to_premium_web = 'click_upgrade_to_premium_web',
  gv_verify_me_pop_up_web = 'gv_verify_me_pop_up_web',
  click_verify_govt_id_web = 'click_verify_govt_id_web',
  gv_click_verify_web = 'gv_click_verify_web',
  gv_click_may_be_later_web = 'gv_click_may_be_later_web',
  //Selfie Verification
  sv_benefits_screen_pop_up_web = 'sv_benefits_screen_pop_up_web',
  sv_benefits_screen_click_verify_me_web = 'sv_benefits_screen_click_verify_me_web',
  sv_benefits_how_it_works_screen_web = 'sv_benefits_how_it_works_screen_web',
  sv_benefits_how_it_works_verify_me_web = 'sv_benefits_how_it_works_verify_me_web',
  sv_happy_with_photo_screen_web = 'sv_happy_with_photo_screen_web',
  sv_happy_with_photo_submitted_web = 'sv_happy_with_photo_submitted_web',
  sv_status_retry_web = 'sv_status_retry_web',

  // Discount Timer Events
  click_timer_card_web = 'click_timer_card_web',
  sv_status_after_submission_web = 'sv_status_after_submission_web',
  sv_status_blue_tick_web = 'sv_status_blue_tick_web',
  //Premium Crown Badge
  click_premium_crown_badge_web = 'click_premium_crown_badge_web',

  //block-report
  block_initiated_web = 'block_initiated_web',
}

interface EventProperties {
  userId?: string;
  eventtype: string;
  scrname?: string;
  description: string;

  trigger_point?:
    | 'connect_profile'
    | 'decline_profile'
    | 'queued_profile_list_view'
    | 'queued_profile_detail_view'
    | string;
  profile_id?: string | null;
  profile_count?: number;
  matching_profileid?: string;
  filters_updated?: Array<any> | object;
  fieldsupdated?: string | Array<string>;
  cleverTap?: string;
  genderSelected?: string;
  birthDaySelected?: string;
  isMarketingEvent?: boolean;
  photosUploaded?: number;
  firstName?: string;
  email?: string;
  status?: string;
  reason?: string;
  mode?: string;
  valueSelected?: string;
  onboardingStatus?: string;
  hasSparkle?: boolean;
  recieved_req_count?: number;
  profileId?: string;
  daysToExpiry?: number | null;
  planName?: string;
  amount?: number;
  redirectScreen?: string;
  method?: string;
  vas_name?: string;
  vas_plan?: string;
  gst_amount?: any;
  plan_price?: number | string;
  unseen_req_count?: number;
  redirectFrom?: string;
  redirectTo?: string;
  type?: string;
  consumableQty?: number;
  has_message?: boolean;
  selfie_verification_status?: string;
  discountPercentage?: string;

  // Govt-id Prop
  Id_type?: string;
  image_type?: string;
  free_connects_left?: number;
  no_of_days?: number;
  is_gv_mandatory?: boolean;
  isGovtIDVerified?: boolean;
}

export type EventPropertiesToPass = Omit<EventProperties, 'eventtype' | 'description'>;

export type ONBOARDING_EVENT_NAME_TYPE = keyof typeof ONBOARDING_EVENT_NAMES;

export const getEventProps = (
  eventName: ONBOARDING_EVENT_NAME_TYPE,
  payload: EventPropertiesToPass
): EventProperties => {
  switch (eventName) {
    // onboarding events start
    case 'landingpage_screen_web': //not implemented
      return {
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.SIGN_UP,
        description: 'User lands on the landing page on web',
      };
    case 'signup_started_web':
      return {
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.SIGN_UP,
        description: 'User clicks on the “Create Account” button on the landing page',
      };
    case 'mobile_no_web':
      return {
        description: 'User lands on Submit Mobile No ',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.SUBMIT_MOBILE_NUMBER,
      };

    case 'mobile_number_submit_web':
      return {
        description: 'User Submits Mobile No',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.SUBMIT_MOBILE_NUMBER,
      };

    case 'otp_sent_web':
      return {
        description: 'Backend sends the OTP',
        eventtype: EVENT_TYPES.BACKEND_EVENT,
        scrname: SCREEN_NAMES.SUBMIT_MOBILE_NUMBER,
      };
    case 'enter_otp_screen_web':
      return {
        description: 'User lands on the otp screen',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.ENTER_OTP,
      };

    case 'resend_sms_web':
      return {
        description: 'User selects resend OTP',
        eventtype: EVENT_TYPES.BACKEND_EVENT,
        scrname: SCREEN_NAMES.ENTER_OTP,
      };
    case '_sms_send_initiated_web':
      return {
        description: 'Backend initiates SMS send',
        eventtype: EVENT_TYPES.BACKEND_EVENT,
        scrname: SCREEN_NAMES.ENTER_OTP,
      };

    case 'get_otp_on_call_web':
      return {
        description: 'user selects get otp on the call',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.ENTER_OTP,
      };
    case 'incorrect_otp_entered_web':
      return {
        description: 'users enters incorrect otp',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.ENTER_OTP,
      };

    case 'profile_signup_name_web':
      return {
        description: 'user lands on the profile signup - name screen',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.PROFILE_SIGN_UP_NAME,
        ...payload,
      };
    case 'continue_on_profile_name_web':
      return {
        description: 'user enters the name/user clicked continue after entering name',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PROFILE_SIGN_UP_NAME,
        ...payload,
      };
    case 'profile_signup_gender_web':
      return {
        description: 'user lands on the profile - gender screen',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.PROFILE_SIGN_UP_GENDER,
        ...payload,
      };
    case 'gender_select_web':
      return {
        description: 'user selects gender',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PROFILE_SIGN_UP_GENDER,
        ...payload,
      };
    case 'profile_signup_birthday_web':
      return {
        description: 'user lands on the profile signup - birthday screen',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.PROFILE_SIGN_UP_BIRTHDAY,
        ...payload,
      };
    case 'continue_with_birthdate_web':
      return {
        description: 'user selects birthday',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PROFILE_SIGN_UP_BIRTHDAY,
        ...payload,
      };
    case 'profile_signup_emailid_web':
      return {
        description: 'user lands on the email id page',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.PROFILE_SIGN_UP_EMAIL_ID,
        ...payload,
      };
    case 'continue_with_emailid_web':
      return {
        description: 'user clicks on continue after entering email id',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PROFILE_SIGN_UP_EMAIL_ID,
        ...payload,
      };

    case 'signup_sneak_peek_web':
      return {
        description: 'user lands on the sneak peek screen',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.SIGN_UP_SNEAK_PEAK,
        ...payload,
      };
    case 'continue_with_sneak_peek_web':
      return {
        description: 'user clicks on continue on the sneak peak screen',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.SIGN_UP_SNEAK_PEAK,
        ...payload,
      };
    case 'signup_add_photos_web':
      return {
        description: 'user lands on the add photos screen',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.SIGNUP_ADD_PHOTOS,
        ...payload,
      };
    case 'signup_select_photos_web':
      return {
        description: 'user is prompted to add photos',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.SIGNUP_ADD_PHOTOS,
        ...payload,
      };
    // case "photo_selection_limit_reached_web":
    //   return {
    //     description: "user is prompted that she has reached the max limit of the profiles added",
    //     eventtype: EVENT_TYPES.USER_ACTION,
    //     scrname: SCREEN_NAMES.SIGNUP_ADD_PHOTOS,
    //     ...payload,
    //   };

    case 'photo_upload_web':
      return {
        description: 'user clicks on the photo upload',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.SIGNUP_ADD_PHOTOS,
        ...payload,
      };
    case 'continue_add_photos_web':
      return {
        description: 'user clicks on continue when she has added 1/more photo',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.SIGNUP_ADD_PHOTOS,
        ...payload,

        // photos_added: 1 // TODO :  we need to get the number of photos added
      };
    case '_photos_upload_success_web': // ?
      return {
        description: 'user clicks on the photo upload',
        eventtype: EVENT_TYPES.BACKEND_EVENT,
        scrname: SCREEN_NAMES.SIGNUP_ADD_PHOTOS,
        ...payload,
      };

    case 'signup_complete_web':
      return {
        description: 'Sign_Up is successful',
        eventtype: EVENT_TYPES.BACKEND_EVENT,
        scrname: SCREEN_NAMES.SIGN_UP,
        ...payload,
      };
    case 'ai_animation_web':
      return {
        description: 'AI Animation Screen Pops up',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.AI_ANIMATION,
        ...payload,
      };
    // onboarding events end
    // --------------------------------------------------

    // home-screen events start
    case 'recommended_profile_web':
      return {
        // eventName: "recommended_profile",
        description: 'user is shown matching profiles',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.RECOMMENDED_PROFILE,
      };
    case 'click_connect_web':
      return {
        // eventName: "click_connect",
        description: 'user clicks to connect to the matching profile',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.RECOMMENDED_PROFILE,
      };

    case 'click_not_interested_web':
      return {
        // eventName: "click_not_interested",
        description: 'user clicks on not interested icon',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.RECOMMENDED_PROFILE,
      };
    case 'click_profile_web':
      return {
        description: 'User clicks on profile icon',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.RECOMMENDED_PROFILE,
        ...payload,
      };
    // home screen events end

    // modal events start
    case 'download_app_screen_web':
      return {
        description: 'User sees the download app screen as per trigger point',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.DOWNLOAD_APP,
        ...payload,
      };

    case 'click_download_app_web':
      return {
        description: 'User clicks on download app button',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.DOWNLOAD_APP,
        ...payload,
      };

    case 'continue_app_screen_web':
      return {
        description: 'User sees the continue to app screen ',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.DOWNLOAD_APP,
        ...payload,
      };

    case 'click_continue_app_web':
      return {
        description: 'User clicks on continue to app from modal',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.DOWNLOAD_APP,
        ...payload,
      };

    // modal events end

    //Partner Preferences

    case 'partner_preference_web':
      return {
        description: 'user lands on the partner preference screen',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.PARTNER_PREFERENCES,
        ...payload,
      };

    case 'click_change_mandatory_preferences_web':
      return {
        description:
          'User clicks on Change Mandatory preferences on PP screen as part of user onboarding',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PARTNER_PREFERENCES,
        ...payload,
      };
    case 'click_save_mandatory_preferences_web':
      return {
        description: 'User saves mandatory preferences',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PARTNER_PREFERENCES,
        ...payload,
      };
    case 'partner_preference_updated_web':
      return {
        description: 'User clicks on Continue after updating the PP',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PARTNER_PREFERENCES,
        ...payload,
      };
    case 'update_must_have_filters_web':
      return {
        description: 'User updates must have preferences',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PARTNER_PREFERENCES,
        ...payload,
      };
    case 'update_nice_to_have_filters_web':
      return {
        description: 'User updates nice to have preferences',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PARTNER_PREFERENCES,
        ...payload,
      };

    case 'click_save_partner_preference_web':
      return {
        description: 'User clicks on save button after changing preferences',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PARTNER_PREFERENCES,
        ...payload,
      };

    case 'lifestyle_pop_up_web':
      return {
        description: 'Lifestyle pops up',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.PARTNER_PREFERENCES,
        ...payload,
      };
    case 'male_salary_above_seven_lakh_web':
      return {
        description: 'Male salary is above 7 lakh',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PERSONAL_DETAILS,
        ...payload,
      };

    // Edit Profile Events

    case 'edit_personal_profile_web':
      return {
        description: 'user lands on personal profile page',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.PERSONAL_DETAILS,
        ...payload,
      };

    case 'edit_personal_profile_v2_continue_web':
      return {
        description: 'User clicks on continue on the personal details screen',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PERSONAL_DETAILS,
        ...payload,
      };

    // Deactivate Account

    case 'click_deactivate_account_web':
      return {
        description: 'User clicks on deactivate account',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.SETTINGS,
        ...payload,
      };

    case 'deactivate_account_select_reason_web':
      return {
        description: 'User selects reason to deactivate account',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.SETTINGS,
        ...payload,
      };

    case 'deactivate_account_web':
      return {
        description: 'User deactivates account',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.SETTINGS,
        ...payload,
      };

    case 'delete_account_deactivate_web':
      return {
        description: 'User chooses to deactivate account from within the delete journey',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.SETTINGS,
        ...payload,
      };

    // Ai Bot events /personal details/prefilled page
    case 'ai_bot_animation_web':
      return {
        description: 'AI bot animation pops up for the user',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: null,
        ...payload,
      };
    case 'ai_bot_prefilled_personal_details_web':
      return {
        description: 'AI Bot prefilled personal details screen pops up',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.AI_BOT_PERSONAL_DETAILS,
        ...payload,
      };
    case 'ai_bot_prefilled_personal_details_back_web':
      return {
        description: 'Click on Back button on prefilled personal details page',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: null,
        ...payload,
      };
    case 'ai_bot_prefilled_personal_continue_web':
      return {
        description: 'User clicks on continue button on AI Personal Details screen',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.AI_BOT_PERSONAL_DETAILS,
        ...payload,
      };
    case 'field_updates_web':
      return {
        description: 'user updates fields',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.AI_BOT_PERSONAL_DETAILS,
        ...payload,
      };
    case 'personal_details_updated_web':
      return {
        description: 'User updated all the required field in Signup flow',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PERSONAL_DETAILS,
        ...payload,
      };

    case 'female_salary_above_four_lakh_web':
      return {
        description: "Females's salary is more than four lakh",
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PERSONAL_DETAILS,
        ...payload,
      };
    case 'user_registration_completed_web':
      return {
        description: 'Users registration completed',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: null,
        ...payload,
      };

    case 'complete_registration_female_web':
      return {
        description: 'Females registration completed',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: null,
        ...payload,
      };
    case 'female_registration_age_24_to_35_web':
      return {
        description: 'Female age is between 24 and 35',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PERSONAL_DETAILS,
        ...payload,
      };
    case 'complete_registration_male_web':
      return {
        description: 'Registration for male user completed',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: null,
        ...payload,
      };

    case 'continue_with_basic_details_web':
      return {
        description: 'continue with basic details',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: null,
        ...payload,
      };

    // Sent Tab Events

    case 'sent_requests_appeared_web':
      return {
        description: 'Sent request screen opens up',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.SENT_REQUEST,
        ...payload,
      };
    case 'nudge_click_send_sparkle':
      return {
        description: 'user click on Nudge send sparkle button',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.SENT_REQUEST,
        ...payload,
      };
    case 'click_send_sparkel_message_web':
      return {
        description: 'User composes on the sparkle message and hit send',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.SENT_REQUEST_LIST_VIEW,
        ...payload,
      };
    case 'queued_requests_screen_web':
      return {
        description: 'User lands on the Queued request screen',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.QUEUED_REQUEST_SCREEN,
        ...payload,
      };
    case 'click_queued_requests_screen_web':
      return {
        description: 'User clicks on the Queued request screen',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.QUEUED_REQUEST_SCREEN,
        ...payload,
      };
    case 'click_send_queued_interest_web':
      return {
        description: 'User clicks on a queued interest from inside the Queued requests screen',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.QUEUED_REQUEST_SCREEN,
        ...payload,
      };
    // Received Request
    case 'recieved_request_web':
      return {
        description: 'recieved request screen opens up',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.RECEIVED_REQUEST,
        ...payload,
      };
    case 'click_accept_web':
      return {
        description: 'user clicks on accept',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.RECEIVED_REQUEST,
        ...payload,
      };
    case 'click_decline_web':
      return {
        description: 'user clicks on decline',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.RECEIVED_REQUEST,
        ...payload,
      };
    case 'click_profile_web':
      return {
        description: 'user clicks on profile icon',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.RECEIVED_REQUEST,
        ...payload,
      };
    case 'scratch_profile_web':
      return {
        description: 'User scratches the profile on Received Request screen',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.RECEIVED_REQUEST,
        ...payload,
      };
    case 'received_request_click_upgrade_web':
      return {
        description: 'User clicks on Upgrade to premium banner on Received Request screen',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.RECEIVED_REQUEST,
        ...payload,
      };
    case 'blurred_profile_click_web':
      return {
        description: 'User clicks on any of the blurred profiles',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.RECEIVED_REQUEST,
        ...payload,
      };
    // Upgrade
    case 'profile_click_upgrade_web':
      return {
        description: 'User clicks on "Upgrade" from Profile settings page',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.MY_PROFILE,
        ...payload,
      };
    case 'premium_plans_web':
      return {
        description: 'User lands on the premium plans page',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.PREMIUM_PLAN,
        ...payload,
      };
    case 'premium_click_upgrade_web':
      return {
        description: 'User selects a plan from the premium plans page and clicks on "Upgrade"',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PREMIUM_PLAN,
        ...payload,
      };
    case 'premium_continue_web':
      return {
        description:
          'User clicks on the "Continue" button on the payment success/Premium membership screen',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PAYMENT_SUCCESS,
        ...payload,
      };
    case 'payment_failure_web':
      return {
        description: 'User lands on the payment failure screen',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.PAYEMENT_FAILURE,
        ...payload,
      };
    case 'click_back_to_premium_web':
      return {
        description: 'User clicks on "Back to Premium" from payment pending/failure page',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PAYEMENT_FAILURE,
        ...payload,
      };
    case 'click_retry_payment_web':
      return {
        description: 'User clicks on Retry payment from the payment failure page',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PAYEMENT_FAILURE,
        ...payload,
      };
    case 'profile_click_myplan_web':
      return {
        description: 'User clicks on My Plan page from Profile settings',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.MY_PROFILE,
        ...payload,
      };
    case 'premium_plan_select_web':
      return {
        description: 'User selects a plan from the premium plans page ',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PREMIUM_PLAN,
        ...payload,
      };
    case '_payment_success_web':
      return {
        description: 'Payment is Successful completed!',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.PREMIUM_PLAN,
        ...payload,
      };

    // Sparkle
    case 'click_sparkle_message_web':
      return {
        description: 'user clicks on the sparkle message icon in the recomended profile screen',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.RECOMMENDED_PROFILE,
        ...payload,
      };

    case 'click_send_sparkel_message_web':
      return {
        description: 'user composes the sparkle messages and hits send',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.RECOMMENDED_PROFILE,
        ...payload,
      };

    case 'sparkle_request_accept_web':
      return {
        description: 'User Accepts Sparkle Request',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.RECEIVED_REQUEST,
        ...payload,
      };
    case 'sparkle_request_decline_web':
      return {
        description: 'User Declines Sparkle Request',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.RECEIVED_REQUEST,
        ...payload,
      };
    case 'vas_plans_web':
      return {
        description: 'User Lands on VAS plans screen',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.PREMIUM_PLAN,
        ...payload,
      };
    case 'vas_click_upgrade_web':
      return {
        description: 'User Clicks on upgrade buttom on VAS plans',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PREMIUM_PLAN,
        ...payload,
      };

    case 'vas_continue_web':
      return {
        description: 'User Clicks Continue on payment success screen',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.PAYMENT_SUCCESS,
        ...payload,
      };
    case 'nudge_click_send_sparkle_web':
      return {
        description: 'user click on Nudge send sparkle button',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.SENT_REQUEST,
        ...payload,
      };

    // Govt-ID Verification Event
    case 'gv_how_it_works_screen_web':
      return {
        description: 'User lands on "how it works" screen',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.GV_HOW_IT_WORKS,
        ...payload,
      };

    case 'gv_click_how_it_works_continue_web':
      return {
        description: 'User clicks on continue on the Govt ID how it works screen',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.GV_HOW_IT_WORKS,
        ...payload,
      };
    case 'gv_click_how_it_works_later_web':
      return {
        description: 'User clicks on "May be later" on the Govt ID how it works screen',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.GV_HOW_IT_WORKS,
        ...payload,
      };
    case 'gv_add_id_screen_web':
      return {
        description: 'User lands on Govt ID adding screen',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.GV_ADD_ID,
        ...payload,
      };
    case 'gv_verification_submit_web':
      return {
        description: 'Govt ID verification details submit',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.GV_ADD_ID,
        ...payload,
      };
    case 'gv_photo_failed_screen_web':
      return {
        description: 'User lands on the Govt ID photo verification failure screen',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.GV_PHOTO_FAILED,
        ...payload,
      };
    case 'gv_submit_click_retry_web':
      return {
        description: 'User clicks on retry after unsuccessful Govt ID verification',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.GV_PHOTO_FAILED,
        ...payload,
      };
    case 'gv_submit_click_do_it_later_web':
      return {
        description: 'User clicks on "Do it later" after unsuccessful Govt ID verification',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.GV_PHOTO_FAILED,
        ...payload,
      };

    case 'gv_intro_pop_up_web':
      return {
        description: 'Introductory/Normal Govt ID verification screen pops up for the user',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        ...payload,
      };

    case 'unlimited_connects_pop_up_web':
      return {
        description: 'Govt ID verification/Upgrade screen pops up for unlimited connects',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        ...payload,
      };

    case 'click_gv_get_verified_web':
      return {
        description: 'User clicks on "Get verified" on the respective pop-up',
        eventtype: EVENT_TYPES.USER_ACTION,
        ...payload,
      };

    case 'click_skip_for_now_web':
      return {
        description: 'User clicks on "Skip for now" on the respective pop-up',
        eventtype: EVENT_TYPES.USER_ACTION,
        ...payload,
      };

    case 'click_upgrade_to_premium_web':
      return {
        description: 'User click on "Upgrade to Premium" on the respective pop-up',
        eventtype: EVENT_TYPES.USER_ACTION,
        ...payload,
      };

    case 'gv_verify_me_pop_up_web':
      return {
        description: 'Verify me screen pops up for the user',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        ...payload,
      };

    case 'click_verify_govt_id_web':
      return {
        description: 'User clicks on a nudge to verify Govt ID',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        ...payload,
      };

    case 'gv_click_verify_web':
      return {
        description: 'User clicks "Verify me" on the Verify Govt ID pop-up ',
        eventtype: EVENT_TYPES.USER_ACTION,
        ...payload,
      };

    case 'gv_click_may_be_later_web':
      return {
        description: 'User clicks "May be later" on the Verify Govt ID pop-up ',
        eventtype: EVENT_TYPES.USER_ACTION,
        ...payload,
      };

    case 'click_premium_crown_badge_web':
      return {
        description: 'User clicks on the premium crown badge on Profile Settings screen',
        eventtype: EVENT_TYPES.USER_ACTION,
        ...payload,
      };

    //Selfie Verification
    case 'sv_benefits_screen_pop_up_web':
      return {
        description: 'Selfie verification benefits screen pop-up',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        ...payload,
      };
    case 'sv_benefits_screen_click_verify_me_web':
      return {
        description: 'User Clicks verify me on the benefits pop-up',
        eventtype: EVENT_TYPES.USER_ACTION,
        ...payload,
      };
    case 'sv_benefits_how_it_works_screen_web':
      return {
        description: 'User lands on "how it works" screen',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.SELFIE_VERIFY,
        ...payload,
      };
    case 'sv_benefits_how_it_works_verify_me_web':
      return {
        description: 'User clicks on take photo on the how it works screen',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.SELFIE_VERIFY,
        ...payload,
      };
    case 'sv_happy_with_photo_screen_web':
      return {
        description: 'User lands on "happy with photo" screen',
        eventtype: EVENT_TYPES.SCREEN_OPENING,
        scrname: SCREEN_NAMES.SELFIE_VERIFY,
        ...payload,
      };
    case 'sv_happy_with_photo_submitted_web':
      return {
        description: 'User submits photo he clicked',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.SELFIE_VERIFY,
        ...payload,
      };
    case 'sv_status_retry_web':
      return {
        description: 'User Clicks on retry after unsuccessfuly verification',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.SELFIE_VERIFY,
        ...payload,
      };
    case 'sv_status_retry_web':
      return {
        description: 'User Clicks on retry after unsuccessfuly verification',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.SELFIE_VERIFY,
        ...payload,
      };

    // Discount_Timer

    case 'click_timer_card_web':
      return {
        description: 'User clicks on the timer card from any of the nudge points',
        eventtype: EVENT_TYPES.USER_ACTION,
        ...payload,
      };

    case 'sv_status_after_submission_web':
      return {
        description: 'Status from Backend after Selfie Verification',
        eventtype: EVENT_TYPES.BACKEND_EVENT,
        scrname: SCREEN_NAMES.SELFIE_VERIFY,
        ...payload,
      };
    case 'sv_status_blue_tick_web':
      return {
        description: 'User lands on blue tick screen after succesful verification',
        eventtype: EVENT_TYPES.BACKEND_EVENT,
        ...payload,
      };
    //block-report
    case 'block_initiated_web':
      return {
        description: 'User confirms on block',
        eventtype: EVENT_TYPES.USER_ACTION,
        scrname: SCREEN_NAMES.RECOMMENDATION_SCREEN,
        ...payload,
      };
    default:
      throw new Error('Invalid event name');
  }
};

export interface MixPanelUser {
  firstName?: string;
  lastName?: string;
  $name?: string;
  $email?: string;
  $phone?: string;
  gender?: string;
  // locations?;
  // migrationStatus?;
  // age?;
  dob?: string;
  $city?: string;
}
