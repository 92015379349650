import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import update from 'immutability-helper';
import { HTTPError } from 'ky';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useQueryClient } from 'react-query';

import { CustomDragLayer } from '@components/ImageUpload/CustomeLayer';
import SecureText from '@components/SecureText';
import { Button, Error, ImageInput } from '@components/index';
import { StepPictureUploadTips } from '@components/onBoardingSteps';
import { ENDPOINTS } from '@configs/api.config';
import { USER_ONBOARDING_STATUS } from '@configs/onBoarding.config';
import {
  useDeleteImageMutation,
  useMutateUserData,
  useRearrangeImages,
  useUploadImageMutation,
} from '@hooks/mutations';
import { useGetUser } from '@hooks/queries';
import useAnalyticsManager from '@hooks/useAnalyticsManager';
import AFApi from '@libs/appsflyer';
import { User } from '@libs/types';
import {
  extractLocationString,
  getAge,
  getStringAfterLastSlash,
  isEmpty,
  resizeArray,
} from '@utils/general';
import { useGlobalStore } from '@zustand/store';

const BottomSheetComponent = dynamic(async () => {
  const { BottomSheet } = await import('react-spring-bottom-sheet');
  return BottomSheet;
});

// import { motion } from "framer-motion";

export interface handleImageArgs {
  action: 'add' | 'remove';
  localImageUrl?: string;
  imageFile?: File;
  imageInputComponentIndex?: number;
}

export interface imageData {
  uploadedImageUrl: string;
  localImageUrl: string;
  isUploading: boolean;
  id?: any;
}

interface Props {
  carouselIndex: number;
  setCarouselIndex: Dispatch<SetStateAction<number>>;
  setOnboardingComplete?: Dispatch<SetStateAction<boolean>>;
  showHeading?: boolean;
  setOnboardingStep?: Dispatch<SetStateAction<number>>;
  editMode?: boolean;
  ifSecureText?: boolean;
  onImageDataChange: () => void;
}

// bad ->> good -> good

let globalImages: imageData[];

const generateImagesArray = (uploadedImages: string[], images: imageData[]): imageData[] => {
  const x = resizeArray(uploadedImages, 6, null).map((imageUrl: string, index) => {
    return {
      uploadedImageUrl: imageUrl,
      localImageUrl: images ? images[index].localImageUrl : null,
      isUploading: false,
      id: 'id' + index,
    };
  });
  globalImages = x;
  return x;
};

/**
 * handles the image upload and deletion
 */
const StepPictureUpload: FC<Props> = ({
  carouselIndex,
  ifSecureText,
  setCarouselIndex,
  showHeading = true,
  editMode,
  onImageDataChange,
}) => {
  const analyticsManager = useAnalyticsManager();

  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  const router = useRouter();
  const { data: user } = useGetUser();

  const uploadedImagesUrl = user?.images || [];

  const [images, setImages] = useState<imageData[]>(() =>
    generateImagesArray(uploadedImagesUrl, null)
  );

  const toggleNewUser = useGlobalStore((state) => state.toggleNewUser);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const userDataMutation = useMutateUserData({
    onSuccess: (response: User) => {
      router.replace('/ai/matches');
      toggleNewUser(true); // if true , then show AI Animation
      analyticsManager.sendEvent('signup_complete_web', { userId: user?.id }, ['mixpanel']);
      analyticsManager.associateMixpanelUser({
        $city: response.locations?.features[0]?.properties.city,
      });
      analyticsManager.sendEvent(
        'continue_add_photos_web',
        {
          userId: user?.id,
          cleverTap: 'yes',
          isMarketingEvent: true,
          photosUploaded: images?.length,
        },
        'all'
      );
      const userAge = getAge(user.dateOfBirth);
      const userCity = extractLocationString(user?.locations, 'residential', 'city');
      const afApi = new AFApi();

      /* Apps Flyer Events */
      afApi.getAppsFlyerInstance().constructor('pba', 'event', {
        eventType: 'EVENT',
        eventValue: {
          af_customer_user_id: user.id,
          gender: user.gender,
          age: userAge,
          af_city: userCity,
        },
        eventName: 'continue_add_photos_web',
      });
    },
    onError: () => {
      setLoading(false);
      setError('Something went wrong. Please try again.');
    },
  });

  const rearrangeImageMutation = useRearrangeImages({
    onSuccess: () => {
      const dataToBeSent: Array<string> = [];

      images.map((i) => {
        if (!isEmpty(i.uploadedImageUrl)) {
          dataToBeSent.push(i?.uploadedImageUrl);
        }
        return null;
      });
      queryClient.setQueryData(ENDPOINTS.ME, (oldData: User) => ({
        ...oldData,
        images: dataToBeSent,
      }));
      if (onImageDataChange) onImageDataChange();
    },
  });

  const uploadImageMutation = useUploadImageMutation({
    onSuccess: (response: { url: string }, variables) => {
      const tempImages = [...globalImages];
      tempImages[variables.index] = {
        uploadedImageUrl: response.url,
        isUploading: false,
        localImageUrl: null,
      };
      globalImages = tempImages;

      const uploadedImageUrls = tempImages.filter((image) => image.uploadedImageUrl);
      const localImageUrls = tempImages.filter((image) => image.localImageUrl);
      const filteredImages = uploadedImageUrls.concat(localImageUrls);
      globalImages = resizeArray<imageData>(filteredImages, 6, {
        uploadedImageUrl: null,
        localImageUrl: null,
      });
      setImages(globalImages);
      queryClient.setQueryData(ENDPOINTS.ME, (oldData: User) => ({
        ...oldData,
        images: [...oldData.images, response.url],
      }));

      setError(null);
      if (onImageDataChange) onImageDataChange();
    },
    onError: async (error: HTTPError, variables) => {
      if (error instanceof TypeError) {
        setError('Something went wrong! Check network speed!');
      } else {
        setError('Looks like some of your photo do not follow our guidelines.');
      }

      const tempImages = [...globalImages];
      tempImages[variables.index] = {
        ...tempImages[variables.index],
        isUploading: false,
        localImageUrl: null,
      };
      globalImages = tempImages;

      //  sort tempImages by uploadedImageUrl and localImageUrl
      const uploadedImageUrls = tempImages.filter((image) => image.uploadedImageUrl);
      const localImageUrls = tempImages.filter((image) => image.localImageUrl);
      const filteredImages = uploadedImageUrls.concat(localImageUrls);
      setImages(() =>
        resizeArray<imageData>(filteredImages, 6, { uploadedImageUrl: null, localImageUrl: null })
      );
    },
  });

  const deleteImageMutation = useDeleteImageMutation({
    onSuccess: (data, variables: number) => {
      const filteredImages = images.filter((_, i) => i !== variables);
      setImages(() =>
        resizeArray<imageData>(filteredImages, 6, { uploadedImageUrl: null, localImageUrl: null })
      );
      queryClient.setQueryData(ENDPOINTS.ME, (oldData: User) => ({
        ...oldData,
        images: oldData.images.filter((image, index) => index !== variables),
      }));
      if (onImageDataChange) onImageDataChange();
    },
  });

  useEffect(() => {
    analyticsManager.sendEvent('signup_add_photos_web', { userId: user?.id }, ['mixpanel']);
  }, []);

  useEffect(() => {
    setImages(() => generateImagesArray(uploadedImagesUrl, images));
  }, [uploadedImagesUrl]);

  useEffect(() => {
    router.prefetch('/ai/matches');
  }, []);

  useEffect(() => {
    // enable the submit button , if there is at-least one images
    setIsButtonEnabled && images.filter((image) => image.uploadedImageUrl).length >= 1
      ? setIsButtonEnabled(true)
      : setIsButtonEnabled(false);
  }, [setIsButtonEnabled, images]);

  const handleImage = ({
    action,
    localImageUrl,
    imageFile,
    imageInputComponentIndex,
  }: handleImageArgs) => {
    if (action === 'add') {
      // prepare the data to be uploaded
      const formData = new FormData();
      formData.append('image', imageFile);

      const tempImages = [...images];

      // if action is add , then replace the nearest null value in the array
      let flag = 0;
      let indexToUpdate: number;
      images.forEach((image, i) => {
        if (!flag && image.uploadedImageUrl === null && !image.localImageUrl) {
          tempImages[i] = {
            ...images[i],
            localImageUrl: localImageUrl,
            isUploading: true,
          };

          flag = 1;
          indexToUpdate = i;
        }
      });
      globalImages = tempImages;

      setImages(tempImages);

      uploadImageMutation.mutate({
        formData,
        //@ts-ignore :: don't worry about the ts error,
        index: indexToUpdate,
        url: localImageUrl,
        images: tempImages,
      });
    } else {
      deleteImageMutation.mutate(imageInputComponentIndex);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isButtonEnabled) return;
    setLoading(true);

    userDataMutation.mutate({
      status: USER_ONBOARDING_STATUS.AI_DETAILS,
    });
  };

  // Move card On drag and drop and update the array
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    let updatedData = [];

    setImages((prevCards) => {
      updatedData = update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      });
      globalImages = updatedData;
      callRearrangeApi(updatedData);
      return updatedData;
    });
  }, []);

  // Calls the rearrange mutation.
  const callRearrangeApi = (updatedData) => {
    const dataToBeSent: Array<string> = [];
    updatedData.map((i) => {
      if (!isEmpty(i.uploadedImageUrl)) {
        dataToBeSent.push(getStringAfterLastSlash(i?.uploadedImageUrl));
      }
      return null;
    });
    rearrangeImageMutation.mutate({ images: dataToBeSent });
  };

  const renderImageInput = () => {
    return images?.map((image, index) => {
      return (
        <ImageInput
          id={image.id}
          data={image}
          key={image.id}
          handleImage={handleImage}
          index={index}
          showDeleteButton={
            images.filter((imageInstance) => imageInstance.uploadedImageUrl).length === 1 &&
            index === 0
              ? false
              : true
          }
          moveCard={moveCard}
          disableDelete={images.filter((imageInstance) => imageInstance.isUploading).length >= 1} // disable the delete button if there is any image uploading | as we are handling optimistic UI here , there might be some unexpected behavior
        />
      );
    });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames('relative flex-1 py-4 !z-20   flex flex-col ', {
        'pt-24': !editMode,
      })}
    >
      <main className="flex flex-col flex-1 space-y-5">
        {showHeading && <h4>Add photos</h4>}
        <div className="flex flex-wrap justify-between gap-y-4">
          {renderImageInput()}
          <CustomDragLayer />
        </div>
        <p className="text-sm">
          To increase your visibility 5X more, add 3 to 4 recent high resolution photos following
          our{' '}
          <span
            className="!inline-block cursor-pointer text-primaryPink"
            onClick={() => setIsBottomSheetOpen(true)}
          >
            guidelines
          </span>{' '}
          .
        </p>

        {error && <Error message={error} />}

        <BottomSheetComponent
          open={isBottomSheetOpen}
          onDismiss={() => setIsBottomSheetOpen(false)}
        >
          <div className="flex flex-col p-5 pb-10 space-y-4">
            <StepPictureUploadTips
              carouselIndex={carouselIndex}
              setCarouselIndex={setCarouselIndex}
              showHeader={false}
              showButton={false}
            />
          </div>
        </BottomSheetComponent>
      </main>
      {ifSecureText && <SecureText />}
      {!editMode && (
        <Button
          isLoading={loading}
          className="w-full"
          label="Continue"
          type="submit"
          disabled={!isButtonEnabled}
          spanId="onboarding-continue-photo-upload"
        />
      )}
    </form>
  );
};

export default StepPictureUpload;
