import moment from 'moment';

import { Gender } from '@libs/types';

/**
 * @description returns range of numbers from start to end (inclusive) as an array
 */
export const range = (start: number, end: number) => {
  return Array(end - start + 1)
    .fill(null)
    .map((_, idx) => start + idx);
};

export const calculateAge = (birthday: string) => {
  // birthday is a date
  if (isEmpty(birthday)) {
    return '';
  }
  const ageDifMs = Date.now() - new Date(birthday).getTime();
  const ageDate = new Date(ageDifMs); // milliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export function isEmpty(value) {
  /* falsy value check */
  if (!value) {
    return true;
  }

  if (typeof value === 'number') {
    return false;
  }

  /* Array check */
  if (Array.isArray(value)) {
    return value.length === 0;
  }

  /* String check */
  if (typeof value === 'string' || value instanceof String) {
    return ['null', ''].includes(value.trim());
  }

  /* Object check */
  if (value?.constructor.name === 'Object') {
    return Object.keys(value).length === 0;
  }

  /* fallback return for other types like Functions */
  return true;
}

export const incomeConverter = ({ incomeFrom, incomeTo }) => {
  if (!incomeFrom && incomeTo) {
    return `0 - ${incomeTo / 100000} Lpa`;
  }

  if (incomeFrom && incomeTo) {
    return `${incomeFrom / 100000} - ${incomeTo / 100000} Lpa`;
  }

  if (incomeFrom && !incomeTo) {
    return `${incomeFrom / 100000} + Lpa`;
  }
  return '';
};

// used as placeholder for Next Image
// export const base64BlurUrl =
//   "data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP837lhKQAHpgLfvNBE1wAAAABJRU5ErkJggg==";

const base64BlurUrl_yellow =
  'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMM+OPlAwAFHgHkr+b9XwAAAABJRU5ErkJggg==';
const base64BlurUrl_orange =
  'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8f4KhHgAG3QJI28LHvQAAAABJRU5ErkJggg==';
export const base64BlurUrl_gray =
  'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPs65u4HgAFvQJeVBF35QAAAABJRU5ErkJggg==';

const base64BlurUrl_blue =
  'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mOU+/zfBwAFpQJev5yKqgAAAABJRU5ErkJggg==';
const base64BlurUrl_violet =
  'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMstf/jAwAE3wH+UhFp6QAAAABJRU5ErkJggg==';

export const getRandomBlurUrl = () => {
  const randomBlurUrlArray = [
    base64BlurUrl_blue,
    base64BlurUrl_gray,
    base64BlurUrl_orange,
    base64BlurUrl_violet,
    base64BlurUrl_yellow,
  ];

  return randomBlurUrlArray[Math.floor(Math.random() * randomBlurUrlArray.length)];
};

export const resizeArray = <T>(arr: T[], newSize: number, defaultValue: unknown) => {
  return [...arr, ...Array(Math.max(newSize - arr.length, 0)).fill(defaultValue)];
};

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const getMonthIndex = (month: string) => {
  if (!month) return NaN;
  const index = months.indexOf(month) + 1;
  return index?.toString().length === 1 ? `0${index}` : index;
};
export const getMonthString = (month: number) => months[month - 1];

export const isValidDate = (dateString) => {
  return moment(dateString, 'YYYY-MM-DD', true).isValid();
};

// TODO : refactor this : copied from app code
export const retrieveDisplayImage = (src: string, crop = true) => {
  if (!isEmpty(src)) {
    const lastIndex = src.lastIndexOf('.');
    const imageUrl = src.substring(0, lastIndex);
    const imageExtension = src.substring(lastIndex + 1);
    if (crop) return `${imageUrl}_crop.${imageExtension}`;
    else return `${imageUrl}.${imageExtension}`;
  }
  return src;
};

export const getModalContent = (
  type: 'all_decline' | 'connect' | 'existing_user',
  payload?: { noOfMatches?: string | number; name?: string }
) => {
  switch (type) {
    case 'all_decline':
      const text = payload.noOfMatches === 'thousands' ? 'thousands of' : payload.noOfMatches;
      return {
        title: 'More matches, more chances!',

        body: `${text} matches are waiting on the other side. Find your betterhalf today`,
        buttonText: 'Download App',
      };

    case 'connect':
      return {
        title: `Chat with ${payload.name}`,

        body: 'You never know, she could be the one.',
        buttonText: 'Download App',
      };

    case 'existing_user':
      return {
        title: 'We know you love us!',

        body: 'Already have the app. Find your betterhalf today',
        buttonText: 'Open App',
      };
  }
};

export function uniqueIdGenerator() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const extractLocationObject = (locations, type: 'hometown' | 'residential') => {
  return locations?.features?.filter((item) => item.properties.tag === type)[0];
};

export const extractLocationString = (
  locations,
  type: 'hometown' | 'residential',
  pick?: 'city' | 'state' | 'country'
) => {
  const location = locations?.features?.filter((item) => item.properties.tag === type)[0];
  const { properties: { city = '', state = '', country = '' } = {} } = location || {};
  if (!city) return undefined;
  if (pick === 'city') return city;
  if (pick === 'state') return state;
  if (pick === 'country') return country;
  return `${city}, ${state ? `${state} , ${country}` : country} `;
};

export const checkIfEligibleForMarriage = (gender: Gender, dateOfBirth: string) => {
  const age = new Date().getFullYear() - new Date(dateOfBirth).getFullYear();

  return (
    (gender === 'female' && age >= 18 && age <= 50) || (gender === 'male' && age >= 21 && age <= 50)
  );
};

export const getJoinedValue = (value: Array<any>): string => {
  if (typeof value[0] === 'string') {
    return value.join(', ');
  } else if (typeof value[0] === 'object') {
    return value.reduce(function (a, b) {
      return a + ['', ', '][+!!a.length] + b.displayText;
    }, '');
  }
};
export const __DEV__ = process.env.NODE_ENV === 'development';
export const isDev = () => __DEV__;

export const getAge = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const getStringAfterLastSlash = (str) => {
  const splitString = str.split('/');
  return splitString[splitString.length - 1];
};

export const MonthsData = {
  Jan: {
    name: 'January',
    short: 'Jan',
    number: 1,
    days: 31,
  },
  Feb: {
    name: 'February',
    short: 'Feb',
    number: 2,
    days: 28,
  },
  Mar: {
    name: 'March',
    short: 'Mar',
    number: 3,
    days: 31,
  },
  Apr: {
    name: 'April',
    short: 'Apr',
    number: 4,
    days: 30,
  },
  May: {
    name: 'May',
    short: 'May',
    number: 5,
    days: 31,
  },
  Jun: {
    name: 'June',
    short: 'Jun',
    number: 6,
    days: 30,
  },
  Jul: {
    name: 'July',
    short: 'Jul',
    number: 7,
    days: 31,
  },
  Aug: {
    name: 'August',
    short: 'Aug',
    number: 8,
    days: 31,
  },
  Sep: {
    name: 'September',
    short: 'Sep',
    number: 9,
    days: 30,
  },
  Oct: {
    name: 'October',
    short: 'Oct',
    number: 10,
    days: 31,
  },
  Nov: {
    name: 'November',
    short: 'Nov',
    number: 11,
    days: 30,
  },
  Dec: {
    name: 'December',
    short: 'Dec',
    number: 12,
    days: 31,
  },
};

export function addHttps(s) {
  if (s !== null) {
    // Split the string into an array based on ':'
    const parts = s?.split(':');
    // Replace the first element with "https://"
    parts[0] = 'https';
    // Join the array back into a string using ':' as the separator
    return parts.join(':');
  }
  return null;
}
// get a value from a nested objects

export function GetNestedObjectKeyValue(obj, key, parent_key) {
  let val;
  function getValue(object, key_) {
    if (typeof object === 'object') {
      if (Object.keys(object).includes(parent_key)) {
        val = object[parent_key][key_];
        return;
      } else {
        Object.values(object).some((next) => {
          if (!val) {
            getValue(next, key);
          }
          return '';
        });
      }
    }
  }
  getValue(obj, key);
  return val;
}

export function getContactMessage(user, obj, key, parent_key) {
  const { email, mobile, id } = user;
  return `${GetNestedObjectKeyValue(obj, key, parent_key)}

  \n \n Details \n ------- \n User Id: ${id || ''} \n Email: ${email || ''} \n Phone number: ${
    mobile || ''
  }
  `;
}
