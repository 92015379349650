/**
 * Email validation based on the same logic used in the backend (Django Email Validator
 */
export function isValidEmail(email: string): boolean {
  const regexValid =
    /^[\w.]+@((?:[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?\.)+)[A-Za-z0-9-]{1,63}[A-Za-z0-9]$/;
  return regexValid.test(email);
}
/**
 * Indian Phone validation
 */
export function isValidPhoneNumber(phoneNumber: string): boolean {
  const regexValid = /^[1-9]\d{9}$/; // TODO : for testing purpose | change this back to /^[6-9]\d{9}$/
  // const regexValid = /^[6-9]\d{9}$/;
  return regexValid.test(phoneNumber);
}

export function isValidName(name){
  const regexValid = /^[a-zA-Z ]*$/;
  return regexValid.test(name)
}

/**
 * Indian Phone validationValidates that the input string is a valid date formatted as "yyyy/mm/dd"
 */
export function isValidDate(dateString: string): boolean {
  // First check for the pattern
  const regex_date = /^\d{4}\-\d{1,2}\-\d{1,2}$/;

  if (!regex_date.test(dateString)) {
    return false;
  }

  // Parse the date parts to integers
  const parts = dateString.split("-");
  const day = parseInt(parts[2], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[0], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month == 0 || month > 12) {
    return false;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
    monthLength[1] = 29;
  }

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}
