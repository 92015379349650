import Accordion, { AccordionProps } from "@components/Accordion/Accordion";
import SaveButton from "@components/common/SaveButton";
import { checkIfEligibleForMarriage, getMonthIndex, getMonthString } from "@utils/general";
import { memo, useState } from "react";
import { Calendar } from "..";
import Error from "@components/Error";
import { isValidDate } from "@utils/validation";
import { useMutateUserData } from "@hooks/mutations";
import { getFormattedDate } from "@utils/formatter";
import { Gender } from "@libs/types";

interface Props {
  accordionKey: string;
  isAccordionOpen: boolean;
  toggleAccordionByKey: (key: string) => void;
  savedValue: string;
  updateGlobalStore: (key, value) => void;
  onDateOfBirthChange: (value: string) => void;
  gender: string;
}
// TODO : "Minimum age allowed is 18 for females and 21 for males. Max age is 50"
const Dob = ({
  accordionKey,
  isAccordionOpen,
  toggleAccordionByKey,
  savedValue,
  updateGlobalStore,
  onDateOfBirthChange,
  gender,
}: Props) => {
  const [dob, setDob] = useState(savedValue);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isError, setIsError] = useState(false);

  const { mutate: updateUserData } = useMutateUserData();

  const props: Omit<AccordionProps, "children"> = {
    title: "Date of Birth",
    placeholder: "Eg. 12/08/1990",
    value: isValidDate(dob) ? getFormattedDate(dob) : "",
    isOpen: isAccordionOpen,
    accordionKey,
    toggleAccordion: toggleAccordionByKey,
  };

  const formatDob = (obj) => {
    const { day, month, year } = obj;
    const formattedDate = `${year}-${getMonthIndex(month)}-${day?.toString().length === 1 ? `0${day}` : day}`;
    onDateOfBirthChange(formattedDate);
    setDob(formattedDate);
  };

  const onSave = () => {
    updateGlobalStore(accordionKey, dob);
    updateUserData({ date_of_birth: dob });
  };

  const initialState = {
    currentState: "day",
    day: parseInt(savedValue?.split("-")[2]),
    month: getMonthString(parseInt(savedValue?.split("-")[1])),
    year: parseInt(savedValue?.split("-")[0]),
  };

  const handleEnableSaveButton = (date) => {
    setIsButtonEnabled(isValidDate(date) && checkIfEligibleForMarriage(gender as Gender, date));
  };

  return (
    <Accordion {...props}>
      <div className="flex flex-col flex-1 space-y-3 w-[98%] justify-center mx-auto pt-2">
        <Calendar
          isSaveButtonEnable={handleEnableSaveButton}
          formatDob={formatDob}
          setError={setIsError}
          initialState={initialState}
        />
        {isError && <Error message="Date is not valid" />}
        <SaveButton isButtonEnabled={isButtonEnabled} handleNext={onSave} />
      </div>
    </Accordion>
  );
};

export default memo(Dob);
