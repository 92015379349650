import AccordionWithChips, { AccordionWithChipsProps } from "@components/Accordion/AccordionWIthChips";
import { SETTLE_DOWN } from "@configs/onboarding.personalDetails.config";
import { Item } from "@libs/types";
import { convertToStartCase } from "@utils/formatter";
import { memo, useState } from "react";

interface Props {
  accordionKey: string;
  isAccordionOpen: boolean;
  toggleAccordionByKey: (key: string) => void;
  savedValue: string;
  updateGlobalStore: (key, value) => void;
}

const SettleDown = ({ accordionKey, isAccordionOpen, toggleAccordionByKey, savedValue, updateGlobalStore }: Props) => {
  const [value, setValue] = useState(savedValue);

  const onItemSelect = (item: Item) => {
    setValue(item.value);
    updateGlobalStore(accordionKey, item.value);
  };

  const props: AccordionWithChipsProps = {
    list: SETTLE_DOWN,
    title: "Settle-down",
    placeholder: "Eg: 1-2 years",
    selectedItem: value,
    value: convertToStartCase(value),
    isOpen: isAccordionOpen,
    accordionKey,
    onItemSelect,
    toggleAccordion: toggleAccordionByKey,
  };

  return <AccordionWithChips {...props} />;
};

export default memo(SettleDown);
